//切位订单详情
import Q from 'q';
import moment from 'moment';

import trains_order_checkChangePaymentStatus from '@/lib/data-service/trains/trains_order_checkChangePaymentStatus.js'

import PaymentMethodSelector from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/payment-method-selector/1.0.15/index.vue';

export default {
  components: {
    PaymentMethodSelector
  },
  data() {
    return {
      detail: {},
    };
  },
  computed: {

  },
  methods: {
    req_payment_detail (params) {
      const _this = this;
      Q.when()
        .then(function () {
          return trains_order_checkChangePaymentStatus(params);
        })
        .then(function (res) {
          _this.detail = res;
          _this.newPaySubmit(_this.detail.orderAmount)
        })
    },
    newPaySubmit(orderAmount) {
      const __this = this;
      this.$nextTick(() => {
        this.$refs.aPaymentMethodSelector.init({
          get_order_data() {
            // console.log(__this.$route.query.id)
            // const order_data = {
            //   businessType: 2,
            //   orderId: __this.$route.query.id, //从订单详情接口获取
            //   orderType: 0, //从订单详情接口获取
            //   orderAmount: orderAmount,//orderAmount
            //   subject: __this.detailsData.productName, //产品名称,必填
            // };

            const order_data = {
              companyId: 145,
              // companyId: __this.detailsData.companyId,
              clubId: 1,
              // clubId:  __this.detailsData.clubId || 1,
              orderNo: `{"businessType":2,"paymentType":2,"realName":"ABCDE","token":"7d333b8c-fd7c-44b4-9fb6-624b6eb573dd","travelOrderType":2,"userId":927}`,
              // orderNo: __this.detailsData.orderNo,
              businessContent: {},
              // businessContent: __this.detailsData.businessContent,
              subject: '中亚异域风情十六日全景游(哈萨克，吉尔吉斯，塔吉克，土库曼和乌兹别克斯坦)',
              // subject: __this.detailsData.productName,
              orderAmount: orderAmount,
            };

            return Q.resolve(order_data);
          },
          //支付成功事件处理方法
          pay_successful_event_handler() {
            __this.dialogVisible = true
          },
          //支付失败事件处理方法
          pay_failed_event_handler() {
            __this.dialogVisible = true
          },
        });
      })
    },
  },
  watch: {

  },
  mounted() {
    this.req_payment_detail(this.$route.query.orderNo);
  },
  activated () {

  }

}
